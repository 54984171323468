<template>
    <div>
        <PopTableList
            :form_data_seo="form_data_seo_pop"
            :form_data="form_data_pop"
            :zIndex="2000"
            :columns="pop_columns"
            :visible.sync="user_visible"
            :rowSelection="rowSelection"
            :get_table_list="get_table_list"
            :slot_table_list="['department', 'user_status']"
            @submit="submit"
            rowKey="storage_id"
        >
        <!-- :submit_preprocessing="submit_preprocessing" -->
            <!-- <template slot="department" slot-scope="data">
                <span>{{ data.record.department_join.department_join }}</span>
            </template> -->
            <!-- <template slot="user_status" slot-scope="data">
                <span v-if="data.text == -1">离职</span>
                <span v-else-if="data.text == 1">在职</span>
            </template> -->
            <div slot="pop_footer">
                已选择{{ selectedRowKeys.length || 0 }}个
            </div>
        </PopTableList>
    </div>
</template>

<script>
import PopTableList from './PopTableList'
import { getWareSelect } from "@/api/push";
import props_pop from './props/pop'
import table_pop from './props/table'
const columns = [
    {
        title: "名称",
        dataIndex: "storage_name"
    },
    {
        title: "编号",
        dataIndex: "storage_id",
    },
    {
        title: "电话",
        dataIndex: "storage_phone"
    },
    {
        title: "地址",
        dataIndex: "storage_address",
    }
]

export default {
    name: 'SelecWarehouse',
    components: {
        PopTableList,
    },
    props: {
        ...props_pop,
        ...table_pop,
        select_type: {
            type: String,
            default: 'radio'
        },		
    },

    data() {
        return {
            user_visible: false,
            get_table_list: getWareSelect,
            pop_columns: columns,
            // submit_preprocessing: {
            //     array_to_string: ['department_id']
            // },
            form_data_pop: {
                title: '请选择仓库'
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        options: {}
                    }
                ]
            },
            select_user: {

            },
            select_user_list: [],
            selectedRowKeys: []
        }
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                // this.select_user_list = selectedRows;
                    this.selectedRowKeys =selectedRowKeys
                    const map = new Map()
                    this.select_user_list = [...this.select_user_list, ...selectedRows].filter(item => {
                        return !map.has(item.storage_id) && map.set(item.storage_id, item.storage_id) && this.selectedRowKeys.indexOf(item.storage_id) > -1
                    });

                },
                selectedRowKeys: this.selectedRowKeys,
            };
        },
    },
    watch: {
        visible(new_value) {
            this.user_visible = new_value
        },
        user_visible(new_value) {
            this.$emit('update:visible', new_value)
        },
    },
    created() {
      
    },
    methods: {
        submit() {
            this.$emit('submit', {
                data:this.select_user_list
            })

        }
    }
}
</script>
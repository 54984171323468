<template>
    <div>
        <TableList :columns="columns" :get_table_list="this.get_table_list" :where='where'
            :slot_table_list="['operation','status','goods','goods_put']" :is_created_get_list="false" ref="list">

            <template slot="left_btn">
                <a-button class="float_left" v-auth="'edit'" @click="add()" type="primary">添加产品</a-button>
                <a-button class="float_left" v-auth="'edit'" @click="setWarehouse" type="primary"
                    style="margin-left:10px">仓库设置</a-button>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'edit'" @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical" />
                <span>
                    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                        <a>删除</a>
                    </a-popconfirm>
                </span>
            </template>

        </TableList>


        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">

        </EditPop>
        <a-modal title="仓库设置" :visible="warehouse" @ok="handleOk" @cancel="warehouse = false">
            <div class="sign" style="padding-bottom: 20px;">
                <span>仓库配置：</span>
                <a-radio-group v-model="tracing_auth.type">
                    <a-radio value="all">
                        全部切换
                    </a-radio>
                    <a-radio value="part">
                        部分切换
                    </a-radio>
                </a-radio-group>
            </div>
            <div class="sign" style="padding-bottom: 20px;" v-if="tracing_auth.type=='part'">
                <span>切换仓库：</span>
                <span class="manage_main" v-for="(item,index) in tracing_auth.data" :key="index">
                    <span class="manage_user">
                        {{ item.storage_name }}
                        <img src="../../../assets/img/icon_delete.png" @click="ware_delete(index)" />
                    </span>
                </span>
                <a @click="add_fact" style="display:inline-block;">选择仓库</a>
            </div>
        </a-modal>
        <WarehouseSelectCheck ref="WarehouseSelectCheck" @submit="submit_ware" :visible.sync="show_ware">
        </WarehouseSelectCheck>
    </div>
</template>

<script>
    // @ is an alias to /src
    import EditPop from "@/components/EditPop";
    import Form from "@/components/Form";
    import TableList from "@/components/TableList";
    import codeList from "@/components/codeList";
    import {
        get_template_setting,
        setTracingAuth,
    } from '@/api/push.js'
    import WarehouseSelectCheck from "@/components/WarehouseSelectCheck";
    import {
        getStorageGoodsInfo,
        getDrpGoodsSelect,
        saveStorageGoodsConfig,
        delStorageGoodsConfig,
        getWarehouseSelect
    } from "@/api/traceability";

    import {
		Modal
	} from 'ant-design-vue'

    let columns = [{
            title: "仓库编号",
            dataIndex: "storage_ids",
        },
        {
            title: "仓库名称",
            dataIndex: "storage_names",
        },

        {
            title: "产品名称",
            dataIndex: "goods_name",
        },
        {
            title: "层数",
            dataIndex: "layers",
        },
        {
            title: "每层数量",
            dataIndex: "num",
        },
        {
            title: "操作",
            scopedSlots: {
                customRender: "operation"
            }
        },
    ];

    export default {
        name: "gpoodIndex",
        components: {
            EditPop,
            Form,
            codeList,
            TableList,
            WarehouseSelectCheck
        },
        data() {
            return {
                show_ware: false,
                tracing_auth: {},
                warehouse: false,
                where: {},
                tracing_count: [],
                list_visible: false,
                get_table_list: getStorageGoodsInfo,

                columns,
                visible: false,
                form_data: {
                    title: '添加产品',
                    list: [{
                            type: 'text',
                            name: 'id',
                            hidden: true,
                            options: {
                                initialValue: '',
                            },
                        },
                        {
                            type: "select",
                            name: "storage_ids",
                            title: "仓库",
                            list: [],
                            options: {
                                rules: [{
                                    required: true,
                                    message: "请选择仓库"
                                }]
                            },
                            mode: "multiple"
                        },
                        {
                            type: 'tree-select',
                            name: 'goods_ids',
                            title: "产品名称",
                            placeholder: '请选择',
                            treeData: [],
                            options: {
                                initialValue: '',
                            },
                            multiple: true,
                            replaceFields: {
                                label: "name",
                                value: "id",
                                children: "children"
                            },

                        },
                        {
                            type: "text",
                            name: "layers",
                            title: "层数",
                            options: {
                                initialValue: ''
                            },
                        },
                        {
                            type: "text",
                            name: "num",
                            title: "每层数量",
                            options: {
                                initialValue: ''
                            },
                        },

                    ]
                },
                submit_preprocessing: {
                    array_to_string: ['storage_ids', 'goods_ids']
                }
            };
        },
        async created() {
            this.get_option()

            this.$nextTick(() => {
                this.where.machine = this.$route.query.id
                this.$refs.list.get_list();
            })

        },
        methods: {
            goods_edit(item) {
                this.$router.push({
                    path: "/traceability/goods_setting",
                    query: {
                        machine: item.line_id,

                    }
                });
            },
            changeStatus(data) {
                this.get_Tracing_Count(data)
            },

            edit(data) {
                this.form_data.list.forEach(item => {
                    if (item.name == 'layers') {
                        item.options.initialValue = data.layers;
                    }
                    if (item.name == 'num') {
                        item.options.initialValue = data.num;
                    }
                    if (item.name == 'storage_ids') {
                        item.options.initialValue = data.storage_ids.split(',');
                    }
                    if (item.name == 'goods_ids') {
                        item.options.initialValue = data.goods_ids.split(',');
                    }
                    if (item.name == 'id') {
                        item.options.initialValue = data.id;
                    }
                })

                this.form_data.title = "编辑产品"
                this.visible = true;

            },
            del(data) {
                // 删除
                delStorageGoodsConfig({
                    data: {
                        id: data.id
                    },
                    info: true,
                }).then(res => {
                    this.$refs.list.get_list();
                })
            },

            get_option() {
                // 仓库数据
                getDrpGoodsSelect().then(res => {
                    res.data.list.forEach(item => {
                        item.disabled = true;
                    })
                    this.$method.set_form_list(this.form_data.list, 'goods_ids', 'treeData', res.data.list);
                })
                // 仓库数据
                getWarehouseSelect().then(res => {
                    this.form_data.list.forEach(item => {
                        if (item.name == 'storage_ids') {
                            item.options.initialValue = [];
                        }
                    })
                    res.data.list.forEach(item => {
                        item.key = item.storage_id
                    })
                    this.$method.set_form_list(this.form_data.list, 'storage_ids', 'list', res.data.list);
                    this.$forceUpdate()
                })

            },
            add() {
                this.form_data.title = "添加产品"
                this.form_data.list.forEach(item => {
                    if (item.name == 'layers') {
                        item.options.initialValue = '';
                    }
                    if (item.name == 'num') {
                        item.options.initialValue = '';
                    }
                    if (item.name == 'storage_ids') {
                        item.options.initialValue = [];
                    }
                    if (item.name == 'goods_ids') {
                        item.options.initialValue = [];
                    }
                    if (item.name == 'id') {
                        item.options.initialValue = '';
                    }
                })
                this.visible = true;
            },
            submit(e) {
                let data = e.values;

                saveStorageGoodsConfig({
                    data: {
                        ...data,
                        goods_ids: data.goods_ids.join(','),
                        storage_ids: data.storage_ids.join(','),
                        machine_id: this.$route.query.id
                    },
                    info: true,
                }).then(res => {
                    this.form_data.list.forEach(item => {
                        if (item.name == 'layers') {
                            item.options.initialValue = '';
                        }
                        if (item.name == 'num') {
                            item.options.initialValue = '';
                        }
                        if (item.name == 'goods_ids') {
                            item.options.initialValue = [];
                        }
                        if (item.name == 'id') {
                            item.options.initialValue = '';
                        }
                    })

                    this.visible = false;
                    this.$refs.list.get_list();
                })
            },
            setWarehouse() {
                get_template_setting({
                    data: {

                    }
                }).then(res => {
                    console.log(res)
                    this.tracing_auth = res.data.tracing_auth
                    this.warehouse = true
                })
            },
            add_fact() {
                if (this.tracing_auth.data) {
                    this.$refs.WarehouseSelectCheck.select_user_list = [...this.tracing_auth.data]
                    this.$refs.WarehouseSelectCheck.selectedRowKeys = this.tracing_auth.data.map(item => {
                        return item.storage_id
                    })
                }

                this.show_ware = true
            },
            submit_ware(data) {
                let arr = []
                data.data.forEach(item => {
                    arr.push({
                        storage_id: item.storage_id,
                        storage_name: item.storage_name
                    })
                })
                this.tracing_auth.data = data.data
            },
            handleOk() {
                setTracingAuth({
                    data: {
                        type: this.tracing_auth.type,
                        data: this.tracing_auth.data
                    },
                    info: true
                }).then(res => {
                    this.warehouse = false
                })
            },
            ware_delete(index) {
				let that = this
				Modal.confirm({
					title: '温馨提示',
					content: '确定要删除该仓库吗？',
					okText: '确定',
					cancelText: '取消',
					onOk() {
						that.tracing_auth.data.splice(index, 1);
					}
				});
			
			},
        }
    };
</script>

<style lang="less">
    @import url("../../../assets/less/app.less");

    .max_price_tips {
        margin-left: 99px;
        margin-top: -30px;
        font-size: 13px;
    }

    .float_left {
        margin-right: 8px;
    }

    .max_price_form {
        .item {
            display: flex;

            .right {
                flex: 1;

                .input_number {
                    width: 100%;
                }
            }
        }
    }

    .qrCodeUrlImg {
        margin-left: 110px;
    }

    .page_content_top {
        display: flex;

        div {
            flex: 1;
            text-align: center;
        }
    }

    li.ant-select-tree-treenode-disabled>span:not(.ant-select-tree-switcher),
    li.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper,
    li.ant-select-tree-treenode-disabled>.ant-select-tree-node-content-wrapper span {
        color: #333;
        cursor: default;
    }

    .manage_main {
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 20px;

        .manage_user {
            border: 1px #eeeeee solid;
            border-radius: 5px;
            padding: 5px;

            img {
                width: 16px;
                margin-top: -3px;
                cursor: pointer;
            }
        }
    }
</style>